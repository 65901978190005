
body {
  font-family: 'Open Sans', sans-serif;
  color: $gray-01;
}

h1 {
  font-size: 32px;
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 18px;
  font-weight: bold;
  margin-top: 15px;
}

h4 {
  font-size: 13px;
}

i {
  font-style: italic;
}

p {
  line-height: 22px;
}

.quiet { color: $quiet-color; }
.loud  { color: $loud-color; }
.italic { font-style: italic; }
.bold   { @extend .loud; font-weight: 700; }


strong, b { @extend .bold }

a {
    color: $link-color;
    text-decoration: none;
  
    &:visited {
      color: $link-color;
      text-decoration: none;
    }
  
    &:hover {
    //  text-decoration: underline;
    }
  
    &[disabled] {
      pointer-events: none;
    }
  }
  