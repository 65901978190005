#admin {
  margin-bottom: 50px;
  .question-editor {
    margin-top: 20px;
    li {
      border: 1px solid $gray-04;
      margin-bottom: 20px;
      padding: 15px 5px 15px 15px;
      border-radius: 3px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-shadow: 2px 2px 5px $gray-08;
      input {
        width: 80%;
      }
      button {
        margin-right: 10px;
        background-color: $gray-08;
        border-color: $gray-08;
        color: $gray-01;
        &:hover {
          background-color: white;
          border-color: $gray-01;
        }
      }

    }
    .question-text {
      max-width: 720px;
      line-height: 20px;
    }
    .options {
      display: flex;
      align-items: center;
      #category {
        height: 60px;
        margin-right: 10px;
      }
      .reverse-selection {
        input {
          margin-left: 5px;
          width: auto;
          position: relative;
          top: 1px;
          margin-right: 10px;
        }
      }
    }
  }
  .save {
    margin-right: 10px;
  }
  .data {
    margin-top: 5px;
    margin-left: 32px;
  }

  .error, .complete {
    margin-top: 20px;
  }

  .error {
    color: $red;
    font-weight: bold;
  }

  .complete {
    color: $button-green02;
    font-weight: bold;
  }

  .dialog {
    .warning-text {
      font-size: 18px;
      font-weight: bold;
      margin-right: 20px;
    }
    .green {
      margin-right: 10px;
    }
  }

  .success-message {
    font-size: 18px;
    color: $button-green02;
    font-weight: bold;
  }

  
}