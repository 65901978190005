$base-text-color: #000;
$base-font-size : 16px;
$base-font-family: Roboto, Helvetica, Arial, sans-serif;
$base-font-weight: 400;

$body-max-width: 960px;
$body-min-width: 860px;
$body-left-padding: 16px;

$fixed-font-family: "monospace";
$fixed-font-size: 14px;
$fixed-line-height: 1.6;

$heading-font-family: $base-font-family;
$heading-font-weight: 900;

$quiet-color: #333;
$loud-color: #000;

$link-color: #2240c2;
$link-hover-color: lighten($link-color, 15%);

$breakpoint-phone: 740px;
$breakpoint-tablet: 920px;
