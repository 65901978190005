#header {
  .title, .navigation {
    padding: 20px;
  }
  .title {
    border-bottom: 5px solid $blue-02;
    width: 100%;
    font-weight: 300;
    font-family: 'Nixie One', cursive;
    h1 {
      font-size: 42px;
    }
  }
}
